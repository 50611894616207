import React from "react";
import { HeroPage as HeroPageBrc } from "@britishredcross/component-library/src/components/molecules/hero-page/hero-page";
import { Image as ImageBrc } from "@britishredcross/component-library/src/components/atoms/image/image";
import { type HeroPageModel } from "@britishredcross/kontent-integration/src/models";

export function HeroPage(props: HeroPageModel) {
	const { image, subtitle, title, text } = props.elements;

	const imageElement = image.value.length ? (
		<ImageBrc
			alt={image.value[0]?.description ?? ""}
			src={image.value[0]?.url ?? ""}
			width="528"
			height="436"
		/>
	) : undefined;

	return (
		<HeroPageBrc
			image={imageElement}
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- despite the generated models saying subtitle isn't optional, it is
			subTitle={subtitle?.value}
			text={text.value}
			title={title.value}
		/>
	);
}
