import React from "react";
import { type RichTextProps } from "./rich-text.types";

export function RichText({ children, ...props }: RichTextProps) {
	return (
		<div className="max-content mx-auto grid grid-cols-12 gap-4">
			<div
				className="brc-richtext px-4 prose max-w-full col-span-12 col-start-1 md:col-span-10 lg:col-span-8
      prose-h1:text-5xl 
      prose-h1:md:text-7xl 
      prose-h1:mb-4 
      prose-h1:mt-0
      prose-h2:text-3xl
      prose-h2:md:text-4xl
			brc-rich-text-h2-margin
      prose-h2:mb-2
      prose-h2:md:mb-4
      prose-h3:text-lg
      prose-h3:md:text-2xl
      prose-h3:mb-1
      prose-h3:md:mb-2
      prose-h3:mt-0
      prose-h4:text-sm
      prose-h4:md:text-base
      prose-h4:mb-1
      prose-h4:md:mb-0
      prose-h4:mt-0
      prose-div:text-sm
      prose-div:md:text-base
      prose-div:leading-7:
      prose-p:text-sm
      prose-p:md:text-base
      text-sm
      md:text-base
      prose-p:mb-2
      prose-p:md:mb-4
      prose-p:mt-0
      prose-div:text-black-primary 
      prose-a:font-bold
      prose-a:underline-offset-5 
      prose-a:underline 
      prose-a:text-blue-link  
      hover:prose-a:decoration-3
      hover:prose-a:text-blue-linkHover
      prose-a:antialiased 
      prose-code:text-white prose-code:whitespace-pre-wrap prose-code:bg-black-primary
      mb-6 md:mb-12
      lg:px-0"
				dangerouslySetInnerHTML={{ __html: children }}
				{...props}
			/>
		</div>
	);
}
