import React from "react";
import type { LinkListWithGroupsModel } from "@britishredcross/kontent-integration/dist/esm/models";
import { LinkList as LinkListBrc } from "@britishredcross/component-library/src/components/molecules/link-list/link-list";
import { generateLinks, type GeneratedLink } from "./link-list";

export function LinkListWithGroups(
	props: LinkListWithGroupsModel
): JSX.Element {
	type GeneratedLinkGroup = Record<
		string,
		{
			title: string;
			links: GeneratedLink[];
		}
	>;

	const generateLinkGroups = (): GeneratedLinkGroup => {
		let generatedLinkGroups: GeneratedLinkGroup = {};
		props.elements.link_groups.linkedItems.forEach(
			(item, index: number): void => {
				const group = {
					[`linkListGroup${index}`]: {
						title: item.elements.title.value,
						links: generateLinks(item.elements.links.linkedItems),
					},
				};
				generatedLinkGroups = { ...generatedLinkGroups, ...group };
			}
		);
		return generatedLinkGroups;
	};

	return (
		<LinkListBrc
			linkGroup={generateLinkGroups()}
			linkListTitle={props.elements.title.value}
		/>
	);
}
