"use client";
import React from "react";
import { CardStandalone } from "@britishredcross/component-library/src/components/molecules/card-basic/card-basic";
import { Image as ImageBrc } from "@britishredcross/component-library/src/components/atoms/image/image";
import { PlainText as PlainTextBrc } from "@britishredcross/component-library/src/components/atoms/plain-text/plain-text";
import { type CardModel } from "@britishredcross/kontent-integration/src/models";
import { type SitemapMapping } from "@britishredcross/kontent-integration/src/services/settings-service";
import { useResizeImage, IMAGE_CONTEXT_CARD } from "@/hooks/use-resize-image";
import { getUrlFromMapping } from "@/utils/path";

export function StandaloneCard({
	cardItem,
	mappings,
}: {
	cardItem: CardModel;
	mappings: SitemapMapping[];
}): JSX.Element | null {
	const imageUrl: string = cardItem.elements.image.value[0]?.url ?? "";
	const imageDescription: string =
		cardItem.elements.image.value[0]?.description ?? "";
	const resizedImage = useResizeImage(imageUrl, IMAGE_CONTEXT_CARD);

	const selectedImage = cardItem.elements.image.value[0];
	const imageElement = (
		<ImageBrc
			alt={imageDescription}
			height={resizedImage.height}
			src={resizedImage.url}
			width={resizedImage.width}
		/>
	);

	if (cardItem.elements.link.linkedItems[0] && selectedImage) {
		const linkItem = cardItem.elements.link.linkedItems[0].elements;
		let linkUrl: string | undefined;

		if (linkItem.external_link.value.length > 0) {
			linkUrl = linkItem.external_link.value;
		} else if (linkItem.internal_link.linkedItems[0]) {
			linkUrl = getUrlFromMapping(
				mappings,
				linkItem.internal_link.linkedItems[0].system.codename
			);
		}

		return (
			<CardStandalone
				destination={linkUrl ?? "/"}
				image={imageElement}
				text={<PlainTextBrc>{cardItem.elements.text.value}</PlainTextBrc>}
				title={cardItem.elements.title.value}
			/>
		);
	}

	// Return null if there's no link or selected image
	return null;
}
