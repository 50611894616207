import React from "react";
import { MultiColumnLayout as MultiColumnLayoutBrc } from "@britishredcross/component-library/src/components/organisms/multi-column-layout/multi-column-layout";
import { type MultiColumnLayoutModel } from "@britishredcross/kontent-integration/src/models";
import { type SitemapMapping } from "@britishredcross/kontent-integration/src/services/settings-service";
import { type IContentItem } from "@kontent-ai/delivery-sdk";
import {
	isFileListContentItem,
	isImageContentItem,
	isLinkContentItem,
	isMapStaticContentItem,
	isQuoteContentItem,
	isRichTextContentItem,
	isVideoContentItem,
} from "@britishredcross/kontent-integration/src/utils/kontent-type-checkers";
import { MapStatic } from "./map-static";
import { Quote } from "./quote";
import { RichText } from "./rich-text";
import { Video } from "./video";
import { Image } from "./image";
import { FileList } from "./file-list";
import { Link } from "./link";

// takes in a content item, works out the type and returns the component that should be rendered
// returns null if the content item is not a recognised type for MultiColumnLayout column items
// Whenever a new content type is allowed in a MultiColumnLayout column, it should be added here
// We're not using the util function from utils/get-component-to-render.tsx for two reasons:
// 1. The columns in MultiColumnLayout can only display a subset of content types
// 2. Importing the util function would create a circular dependency as the util function imports this file
function getComponentToRenderInColumn(
	item: IContentItem,
	mappings: SitemapMapping[],
	preview: boolean,
	index: number
): JSX.Element | null {
	switch (true) {
		case isFileListContentItem(item):
			return <FileList {...item} key={item.system.type + index.toString()} />;
		case isLinkContentItem(item):
			return (
				<Link
					linkItem={item}
					mappings={mappings}
					key={item.system.type + index.toString()}
				/>
			);
		case isRichTextContentItem(item):
			return (
				<RichText
					richTextItem={item}
					preview={preview}
					mappings={mappings}
					key={item.system.type + index.toString()}
				/>
			);
		case isImageContentItem(item):
			return <Image {...item} key={item.system.type + index.toString()} />;
		case isVideoContentItem(item):
			return <Video {...item} key={item.system.type + index.toString()} />;
		case isMapStaticContentItem(item):
			return <MapStatic {...item} key={item.system.type + index.toString()} />;

		case isQuoteContentItem(item):
			return <Quote {...item} key={item.system.type + index.toString()} />;
		default:
			return null;
	}
}

export function MultiColumnLayout({
	multiColumnLayoutItem,
	mappings,
	preview,
}: {
	multiColumnLayoutItem: MultiColumnLayoutModel;
	mappings: SitemapMapping[];
	preview: boolean;
}): JSX.Element {
	let displayDivider = false;
	// Check if the divider prop is available
	if (multiColumnLayoutItem.elements.show_bottom_border.value[0]?.codename) {
		const divider =
			multiColumnLayoutItem.elements.show_bottom_border.value[0]?.codename;
		displayDivider = JSON.parse(divider); // Assign the parsed value to displayDivider
	}

	const column1 = multiColumnLayoutItem.elements.column_1.linkedItems
		.map((item, index) => {
			const ContentItem = getComponentToRenderInColumn(
				item,
				mappings,
				preview,
				index
			);
			return ContentItem;
		})
		.filter((item) => item !== null);
	const column2 = multiColumnLayoutItem.elements.column_2.linkedItems
		.map((item, index) => {
			const ContentItem = getComponentToRenderInColumn(
				item,
				mappings,
				preview,
				index
			);
			return ContentItem;
		})
		.filter((item) => item !== null);

	return (
		<MultiColumnLayoutBrc
			columnOne={column1}
			columnTwo={column2}
			displayDivider={displayDivider}
		/>
	);
}
