import React from "react";
import {
	isCardContainerContentItem,
	isContactBannerContentItem,
	isJotformContentItem,
	isFileListContentItem,
	isLinkContentItem,
	isRichTextContentItem,
	isImageContentItem,
	isVadSearchContentItem,
	isVadRecordContentItem,
	isSearchBoxContentItem,
	isSitemapContentItem,
	isOnetrustCookieListContentItem,
	isMultiColumnLayoutContentItem,
	isAccordionContentItem,
	isLinkListContentItem,
	isLinkListWithGroupsContentItem,
	isCtaBannerContentItem,
	isVideoContentItem,
	isMapStaticContentItem,
	isXProfileContentItem,
	isSearchContentItem,
	isQuoteContentItem,
	isHeroPageContentItem,
	isHomePageHeroContentItem,
} from "@britishredcross/kontent-integration/src/utils/kontent-type-checkers";
import { type IContentItem } from "@kontent-ai/delivery-sdk";
import { type SitemapMapping } from "@britishredcross/kontent-integration/src/services/settings-service";
import { CardContainer } from "@/components/content/card-container";
import { ContactBanner } from "@/components/content/contact-banner";
import { Jotform } from "@/components/content/jotform";
import { FileList } from "@/components/content/file-list";
import { Link } from "@/components/content/link";
import { RichText } from "@/components/content/rich-text";
import { VadSearch } from "@/components/content/vad-search";
import { VadRecord } from "@/components/content/vad-record";
import { SearchBox } from "@/components/content/search-box";
import { Sitemap } from "@/components/content/sitemap";
import { OnetrustCookieList } from "@/components/content/onetrust-cookie-list";
import { MultiColumnLayout } from "@/components/content/multi-column-layout";
import { Accordion } from "@/components/content/accordion";
import { LinkList } from "@/components/content/link-list";
import { LinkListWithGroups } from "@/components/content/link-list-with-groups";
import { CtaBanner } from "@/components/content/cta-banner";
import { Video } from "@/components/content/video";
import { MapStatic } from "@/components/content/map-static";
import { XProfile } from "@/components/content/x-profile";
import { Search } from "@/components/search/search";
import { Quote } from "@/components/content/quote";
import { Image } from "@/components/content/image";
import { HeroPage } from "@/components/hero/hero-page";
import { HomepageHero } from "@/components/hero/homepage-hero";

// takes in a content item, works out the type and returns the hero component that should be rendered
// returns null if the content item is not a recognised hero type
export function getHeroComponentToRender(
	item: IContentItem,
	mappings: SitemapMapping[]
): JSX.Element | null {
	switch (true) {
		case isHeroPageContentItem(item):
			return <HeroPage {...item} key={item.system.id} />;
		case isHomePageHeroContentItem(item):
			return (
				<HomepageHero
					homepageHeroItem={item}
					mappings={mappings}
					key={item.system.id}
				/>
			);
		default:
			return null;
	}
}

// takes in a content item, works out the type and returns the component that should be rendered
// returns null if the content item is not a recognised type
export function getComponentToRender(
	item: IContentItem,
	mappings: SitemapMapping[],
	preview: boolean,
	index: number
): JSX.Element | null {
	switch (true) {
		case isCardContainerContentItem(item):
			return (
				<CardContainer
					cardContainerItem={item}
					key={item.system.type + index.toString()}
					mappings={mappings}
				/>
			);
		case isContactBannerContentItem(item):
			return (
				<ContactBanner {...item} key={item.system.type + index.toString()} />
			);
		case isJotformContentItem(item):
			return (
				<Jotform
					jotformItem={item}
					preview={preview}
					key={item.system.type + index.toString()}
				/>
			);
		case isFileListContentItem(item):
			return <FileList {...item} key={item.system.type + index.toString()} />;
		case isLinkContentItem(item):
			return (
				<Link
					linkItem={item}
					mappings={mappings}
					key={item.system.type + index.toString()}
				/>
			);
		case isRichTextContentItem(item):
			return (
				<RichText
					richTextItem={item}
					preview={preview}
					mappings={mappings}
					key={item.system.type + index.toString()}
				/>
			);
		case isImageContentItem(item):
			return <Image {...item} key={item.system.type + index.toString()} />;
		case isVadSearchContentItem(item):
			return <VadSearch {...item} key={item.system.type + index.toString()} />;
		case isVadRecordContentItem(item):
			return <VadRecord {...item} key={item.system.type + index.toString()} />;
		case isSearchBoxContentItem(item):
			return <SearchBox {...item} key={item.system.type + index.toString()} />;
		case isAccordionContentItem(item):
			return <Accordion {...item} key={item.system.type + index.toString()} />;
		case isMultiColumnLayoutContentItem(item):
			return (
				<MultiColumnLayout
					multiColumnLayoutItem={item}
					mappings={mappings}
					preview={preview}
					key={item.system.type + index.toString()}
				/>
			);
		case isLinkListContentItem(item):
			return <LinkList {...item} key={item.system.type + index.toString()} />;
		case isLinkListWithGroupsContentItem(item):
			return (
				<LinkListWithGroups
					{...item}
					key={item.system.type + index.toString()}
				/>
			);
		case isCtaBannerContentItem(item):
			return <CtaBanner {...item} key={item.system.type + index.toString()} />;
		case isVideoContentItem(item):
			return <Video {...item} key={item.system.type + index.toString()} />;
		case isMapStaticContentItem(item):
			return <MapStatic {...item} key={item.system.type + index.toString()} />;
		case isXProfileContentItem(item):
			return <XProfile {...item} key={item.system.type + index.toString()} />;
		case isSearchContentItem(item):
			return <Search {...item} key={item.system.type + index.toString()} />;
		case isQuoteContentItem(item):
			return <Quote {...item} key={item.system.type + index.toString()} />;
		case isSitemapContentItem(item):
			return (
				<Sitemap
					mappings={mappings}
					key={item.system.type + index.toString()}
				/>
			);
		case isOnetrustCookieListContentItem(item):
			return <OnetrustCookieList key={item.system.type + index.toString()} />;

		default:
			return null;
	}
}
