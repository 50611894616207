"use client";
import { type PageModel } from "@britishredcross/kontent-integration/src/models/content-types";
import React, { useEffect, useState } from "react";
import { type SitemapMapping } from "@britishredcross/kontent-integration/src/services/settings-service";
import { useParams } from "next/navigation";
import { type IContentItem } from "@kontent-ai/delivery-sdk";
import { getErrorPage } from "@/data-access/server-actions/get-error-page";
import { getSitemapMappings } from "@/data-access/server-actions/get-sitemap-mappings";
import {
	getComponentToRender,
	getHeroComponentToRender,
} from "@/utils/get-component-to-render";

// eslint-disable-next-line import/no-default-export -- This is necessary because Next.js requires default exports for special pages like error.tsx.
export default function ErrorPage(): JSX.Element {
	const [errorData, setErrorData] = useState<PageModel | null>(null);

	const [mappings, setMappings] = useState<SitemapMapping[] | null>(null);

	const { lang } = useParams<{ lang: string }>();

	useEffect(() => {
		const fetchErrorData = async (): Promise<void> => {
			try {
				const [errorPage, sitemapMappings] = await Promise.all([
					getErrorPage(lang),
					getSitemapMappings(lang),
				]);

				setErrorData(errorPage);
				setMappings(sitemapMappings);
			} catch (error) {
				// eslint-disable-next-line no-console -- Replace with Sentry in future
				console.error("Error fetching error page data:", error);
			}
		};

		void fetchErrorData();
	}, [lang]);

	return (
		<main id="main">
			{errorData && errorData.elements.hero.linkedItems[0] && mappings
				? getHeroComponentToRender(
						errorData.elements.hero.linkedItems[0],
						mappings
					)
				: null}

			{errorData && mappings
				? errorData.elements.content.linkedItems.map(
						(item: IContentItem, index: number) => {
							const Component = getComponentToRender(
								item,
								mappings,
								false,
								index
							);
							return Component;
						}
					)
				: null}
		</main>
	);
}
