import React from "react";
import { RichText as RichTextBrc } from "@britishredcross/component-library/src/components/atoms/rich-text/rich-text";
import {
	type PortableTextExternalLink,
	type PortableTextInternalLink,
	nodeParse,
	transformToPortableText,
} from "@kontent-ai/rich-text-resolver";
import {
	toHTML,
	type PortableTextMarkComponentOptions,
	type PortableTextOptions,
} from "@portabletext/to-html";
import { type RichTextModel } from "@britishredcross/kontent-integration/src/models";
import { type SitemapMapping } from "@britishredcross/kontent-integration/src/services/settings-service";
import { type Elements } from "@kontent-ai/delivery-sdk";
import { WebSpotlightConstants } from "@/utils/web-spotlight";
import { getUrlFromMapping } from "@/utils/path";

const getPortableTextComponents = (
	element: Elements.RichTextElement,
	mappings: SitemapMapping[]
): PortableTextOptions => {
	return {
		components: {
			marks: {
				link: ({
					children,
					value,
				}: PortableTextMarkComponentOptions<PortableTextExternalLink>) => {
					return value?.href
						? `<a href="${value.href}">${children}</a>`
						: children;
				},
				internalLink: ({
					children,
					value,
				}: PortableTextMarkComponentOptions<PortableTextInternalLink>) => {
					const linkedItem = element.links.find(
						(item) => item.linkId === value?.reference._ref
					);

					const url = linkedItem
						? getUrlFromMapping(mappings, linkedItem.codename)
						: null;

					return url ? `<a href="${url}">${children}</a>` : children;
				},
			},
		},
	};
};

export function RichText({
	richTextItem,
	preview,
	mappings,
}: {
	richTextItem: RichTextModel;
	preview: boolean;
	mappings: SitemapMapping[];
}): JSX.Element {
	const parsedTree = nodeParse(richTextItem.elements.text.value);
	const portableText = transformToPortableText(parsedTree);

	let dataKontentElementCodename;
	let dataKontentItemId;

	if (preview) {
		dataKontentElementCodename = {
			[WebSpotlightConstants.dataKontentElementCodename]:
				richTextItem.system.codename,
		};
		dataKontentItemId = {
			[WebSpotlightConstants.dataKontentItemId]: richTextItem.system.id,
		};
	}

	const resolvedHtml = toHTML(
		portableText,
		getPortableTextComponents(richTextItem.elements.text, mappings)
	);

	return (
		<RichTextBrc {...dataKontentElementCodename} {...dataKontentItemId}>
			{resolvedHtml}
		</RichTextBrc>
	);
}
