import { useRef, useEffect } from "react";

/*
	useIsMount() hook
	Returns true on initial render and false on subsequent renders
	Allows us to determine if the component is being mounted for the first time

	Usage:
	const isMount = useIsMount();
	if (isMount) {
		// Component is being mounted
	} else {
		// Component is being updated
	}
*/
export const useIsMount = (): boolean => {
	const isMountRef = useRef(true);
	useEffect(() => {
		isMountRef.current = false;
	}, []);
	return isMountRef.current;
};
