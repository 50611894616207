"use client";

import { DEFAULT_LOCALE } from "@britishredcross/kontent-integration/src/constants/localisation";
import * as Sentry from "@sentry/nextjs";
import NextError from "next/error";
import { useEffect } from "react";

// eslint-disable-next-line import/no-default-export -- This is necessary because Next.js requires default exports for special pages like this one.
export default function GlobalError({
	error,
}: {
	error: Error & { digest?: string };
}): JSX.Element {
	useEffect(() => {
		Sentry.captureException(error);
	}, [error]);

	return (
		<html lang={DEFAULT_LOCALE}>
			<body>
				{/* `NextError` is the default Next.js error page component. Its type
        definition requires a `statusCode` prop. However, since the App Router
        does not expose status codes for errors, we simply pass 0 to render a
        generic error message. */}
				<NextError statusCode={0} />
			</body>
		</html>
	);
}
