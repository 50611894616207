import {
	type Elements,
	ElementType,
	type IContentItem,
} from "@kontent-ai/delivery-sdk";
import { contentTypes } from "../models";
import {
	type RedirectModel,
	type ShopModel,
	type HomepageModel,
	type LinkModel,
	type PageModel,
	type PressReleaseModel,
	type CardContainerModel,
	type ContactBannerModel,
	type AccordionModel,
	type CtaBannerModel,
	type FileListModel,
	type ImageModel,
	type JotformModel,
	type LinkListModel,
	type LinkListWithGroupsModel,
	type MapStaticModel,
	type MultiColumnLayoutModel,
	type OnetrustCookieListModel,
	type QuoteModel,
	type RichTextModel,
	type SearchBoxModel,
	type SearchModel,
	type SitemapModel,
	type VadRecordModel,
	type VadSearchModel,
	type VideoModel,
	type XProfileModel,
	type HomepageHeroModel,
	type HeroPageModel,
} from "../models/content-types";

export function isHomePage(
	contentItem: IContentItem | undefined
): contentItem is HomepageModel {
	return contentItem?.system.type === contentTypes.homepage.codename;
}

export function isPage(
	contentItem: IContentItem | undefined
): contentItem is PageModel {
	return contentItem?.system.type === contentTypes.page.codename;
}

export function isLink(
	contentItem: IContentItem | undefined
): contentItem is LinkModel {
	return contentItem?.system.type === contentTypes.link.codename;
}

export function isRedirectPage(
	contentItem: IContentItem | undefined
): contentItem is RedirectModel {
	return contentItem?.system.type === contentTypes.redirect.codename;
}

export function isShopPage(
	contentItem: IContentItem | undefined
): contentItem is ShopModel {
	return contentItem?.system.type === contentTypes.shop.codename;
}

export function isPressReleasePage(
	contentItem: IContentItem | undefined
): contentItem is PressReleaseModel {
	return contentItem?.system.type === contentTypes.press_release.codename;
}

export function isCardContainerContentItem(
	contentItem: IContentItem | undefined
): contentItem is CardContainerModel {
	return contentItem?.system.type === contentTypes.card_container.codename;
}

export function isContactBannerContentItem(
	contentItem: IContentItem | undefined
): contentItem is ContactBannerModel {
	return contentItem?.system.type === contentTypes.contact_banner.codename;
}

export function isJotformContentItem(
	contentItem: IContentItem | undefined
): contentItem is JotformModel {
	return contentItem?.system.type === contentTypes.jotform.codename;
}

export function isFileListContentItem(
	contentItem: IContentItem | undefined
): contentItem is FileListModel {
	return contentItem?.system.type === contentTypes.file_list.codename;
}

export function isLinkContentItem(
	contentItem: IContentItem | undefined
): contentItem is LinkModel {
	return contentItem?.system.type === contentTypes.link.codename;
}

export function isRichTextContentItem(
	contentItem: IContentItem | undefined
): contentItem is RichTextModel {
	return contentItem?.system.type === contentTypes.rich_text.codename;
}

export function isImageContentItem(
	contentItem: IContentItem | undefined
): contentItem is ImageModel {
	return contentItem?.system.type === contentTypes.image.codename;
}

export function isVadSearchContentItem(
	contentItem: IContentItem | undefined
): contentItem is VadSearchModel {
	return contentItem?.system.type === contentTypes.vad_search.codename;
}

export function isVadRecordContentItem(
	contentItem: IContentItem | undefined
): contentItem is VadRecordModel {
	return contentItem?.system.type === contentTypes._vad_record.codename;
}

export function isSearchBoxContentItem(
	contentItem: IContentItem | undefined
): contentItem is SearchBoxModel {
	return contentItem?.system.type === contentTypes.search_box.codename;
}

export function isSitemapContentItem(
	contentItem: IContentItem | undefined
): contentItem is SitemapModel {
	return contentItem?.system.type === contentTypes.sitemap.codename;
}

export function isOnetrustCookieListContentItem(
	contentItem: IContentItem | undefined
): contentItem is OnetrustCookieListModel {
	return (
		contentItem?.system.type === contentTypes._onetrust_cookie_list.codename
	);
}

export function isMultiColumnLayoutContentItem(
	contentItem: IContentItem | undefined
): contentItem is MultiColumnLayoutModel {
	return (
		contentItem?.system.type === contentTypes._multi_column_layout.codename
	);
}

export function isLinkListContentItem(
	contentItem: IContentItem | undefined
): contentItem is LinkListModel {
	return contentItem?.system.type === contentTypes.link_list.codename;
}

export function isLinkListWithGroupsContentItem(
	contentItem: IContentItem | undefined
): contentItem is LinkListWithGroupsModel {
	return (
		contentItem?.system.type === contentTypes.link_list_with_groups.codename
	);
}

export function isCtaBannerContentItem(
	contentItem: IContentItem | undefined
): contentItem is CtaBannerModel {
	return contentItem?.system.type === contentTypes.cta_banner.codename;
}

export function isVideoContentItem(
	contentItem: IContentItem | undefined
): contentItem is VideoModel {
	return contentItem?.system.type === contentTypes.video.codename;
}

export function isAccordionContentItem(
	contentItem: IContentItem | undefined
): contentItem is AccordionModel {
	return contentItem?.system.type === contentTypes.accordion.codename;
}

export function isMapStaticContentItem(
	contentItem: IContentItem | undefined
): contentItem is MapStaticModel {
	return contentItem?.system.type === contentTypes.map_static.codename;
}

export function isXProfileContentItem(
	contentItem: IContentItem | undefined
): contentItem is XProfileModel {
	return contentItem?.system.type === contentTypes.x_profile.codename;
}

export function isShopDetailsContentItem(
	contentItem: IContentItem | undefined
): contentItem is ShopModel {
	return contentItem?.system.type === contentTypes.shop.codename;
}

export function isSearchContentItem(
	contentItem: IContentItem | undefined
): contentItem is SearchModel {
	return contentItem?.system.type === contentTypes.search.codename;
}

export function isQuoteContentItem(
	contentItem: IContentItem | undefined
): contentItem is QuoteModel {
	return contentItem?.system.type === contentTypes.quote.codename;
}

export function isHomePageHeroContentItem(
	contentItem: IContentItem | undefined
): contentItem is HomepageHeroModel {
	return contentItem?.system.type === contentTypes.homepage_hero.codename;
}

export function isHeroPageContentItem(
	contentItem: IContentItem | undefined
): contentItem is HeroPageModel {
	return contentItem?.system.type === contentTypes.hero_page.codename;
}

// BEGIN - Element type checkers
export function isTaxonomyElement(
	element: Elements.UnknownElement | undefined
): element is Elements.TaxonomyElement {
	return element?.type === ElementType.Taxonomy;
}

export function isRichTextElement(
	element: Elements.UnknownElement | undefined
): element is Elements.RichTextElement {
	return element?.type === ElementType.RichText;
}

export function isTextElement(
	element: Elements.UnknownElement | undefined
): element is Elements.TextElement {
	return element?.type === ElementType.Text;
}

export function isLinkedItemElement(
	element: Elements.UnknownElement | undefined
): element is Elements.LinkedItemsElement {
	return element?.type === ElementType.ModularContent;
}
