import type {
	HeaderProps,
	MenuLinkTwoLevel,
} from "@britishredcross/component-library/src/components/molecules/header/header.types";
import {
	LinkType,
	type LinkProps,
} from "@britishredcross/component-library/src/components/atoms/link/link.types";
import { PageService } from "@britishredcross/kontent-integration/dist/esm/services/page-service";
import {
	SettingsService,
	type SitemapMapping,
} from "@britishredcross/kontent-integration/dist/esm/services/settings-service";
import { contentTypes } from "@britishredcross/kontent-integration/dist/esm/models";
import type {
	PageModel,
	HomepageModel,
	Navigation1LevelModel,
	Navigation2LevelsModel,
	VadRecordModel,
	VadSearchModel,
	DictionaryModel,
} from "@britishredcross/kontent-integration/src/models/content-types";
import { type Metadata } from "next";
import { isPage } from "@britishredcross/kontent-integration/src/utils/kontent-type-checkers";
import { notFound } from "next/navigation";
import {
	LocalisationService,
	type Language,
} from "@britishredcross/kontent-integration/dist/esm/services/localisation-service";
import { DEFAULT_LOCALE } from "@britishredcross/kontent-integration/dist/esm/constants/localisation";
import { availableLanguagesForCollection } from "@/lib/available-languages-for-collection";
import { type RouteProps } from "@/app/[lang]/[[...slug]]/types";
import { deliveryClient } from "./kontent-delivery";
import { AppConfig } from "./app-config";

export async function getSitemapMappings(
	homepageCodename: string,
	preview: boolean,
	lang: string
): Promise<SitemapMapping[]> {
	const settingsService = new SettingsService({
		isPreview: preview,
		environmentId: process.env.NEXT_PUBLIC_KONTENT_ENVIRONMENT_ID || "",
		previewApiKey: process.env.KONTENT_PREVIEW_API_KEY || "",
		assetsDomain: process.env.NEXT_PUBLIC_ASSETS_DOMAIN || "",
		languageCodename: lang,
	});

	const mappings = await settingsService.getSitemapMappings(
		homepageCodename,
		preview
	);

	return mappings;
}

export async function getVadSearch(): Promise<VadSearchModel> {
	const data = await deliveryClient
		.item<VadSearchModel>("vad_search")
		.depthParameter(3)
		.toPromise();

	return data.data.item;
}

export async function getVadRecord(): Promise<VadRecordModel> {
	const data = await deliveryClient
		.item<VadRecordModel>("vad_record")
		.depthParameter(3)
		.toPromise();

	return data.data.item;
}

export async function getPageTitle(
	codename: string,
	isPreview: boolean,
	lang: string
): Promise<string> {
	const pageService = new PageService({
		isPreview,
		environmentId: process.env.NEXT_PUBLIC_KONTENT_ENVIRONMENT_ID || "",
		previewApiKey: process.env.KONTENT_PREVIEW_API_KEY || "",
		assetsDomain: process.env.NEXT_PUBLIC_ASSETS_DOMAIN || "",
		languageCodename: lang,
	});
	return pageService.getAdminTitle(codename);
}

export function getSlugFromMapping(
	mappings: SitemapMapping[],
	codename: string
): string[] | undefined {
	const mapping = mappings.find((m) => m.codename === codename);
	if (mapping) {
		return mapping.slug;
	}

	return undefined;
}

export function getUrlFromMapping(
	mappings: SitemapMapping[],
	codename: string
): string | undefined {
	const slug = getSlugFromMapping(mappings, codename);

	if (!slug) {
		return undefined;
	}

	return `/${slug.join("/")}`;
}

export async function getOneLevelNavigation(
	mappings: SitemapMapping[],
	homepageCodename: string,
	navigationCodename: string,
	isPreview = false,
	lang: string
): Promise<LinkProps[]> {
	const response = deliveryClient
		.item<Navigation1LevelModel>(navigationCodename)
		.queryConfig({
			usePreviewMode: isPreview,
		})
		.languageParameter(lang)
		.depthParameter(4)
		.toPromise();

	const navigation = await response.then((x) => x.data.item);

	const menuLinks: LinkProps[] = navigation.elements.menu_items.linkedItems.map(
		(menuItem) => {
			const title = menuItem.system.name;

			// check if menuitem is an external link or a page or homepage
			if (menuItem.elements.internal_link.linkedItems.length > 0) {
				return {
					title,
					destination: getUrlFromMapping(
						mappings,
						menuItem.elements.internal_link.linkedItems[0]?.system.codename ||
							homepageCodename
					),
					linkType: LinkType.MenuHeader,
					children: [],
				} as LinkProps;
			}

			if (menuItem.elements.external_link.value !== "") {
				const externalLink = menuItem.elements.external_link.value as
					| string
					| undefined;

				return {
					title,
					destination: externalLink,
					linkType: LinkType.MenuHeader,
					children: [],
				} as LinkProps;
			}

			// default to homepage
			return {
				title,
				destination: "/",
				linkType: LinkType.MenuHeader,
				children: [],
			} as LinkProps;
		}
	);
	return menuLinks;
}

export async function getTwoLevelNavigation(
	mappings: SitemapMapping[],
	homepageCodename: string,
	navigationCodename: string,
	isPreview = false,
	lang: string
): Promise<HeaderProps["menuLinksTwoLevel"]> {
	const response = deliveryClient
		.item<Navigation2LevelsModel>(navigationCodename)
		.queryConfig({
			usePreviewMode: isPreview,
		})
		.languageParameter(lang)
		.depthParameter(4)
		.toPromise();

	const navigation = await response.then((x) => x.data.item);

	const menuLinks: HeaderProps["menuLinksTwoLevel"] =
		navigation.elements.menu_groups.linkedItems
			.map((menuGroup) => {
				const title: string = menuGroup.elements.title.value;
				return {
					[title]: {
						header: {
							title,
						} as MenuLinkTwoLevel,
						links: menuGroup.elements.menu_items.linkedItems.map((menuItem) => {
							const subMenuTitle = menuItem.system.name;
							// check if menuitem is an external link or a page or homepage
							if (menuItem.elements.internal_link.linkedItems.length > 0) {
								return {
									title: subMenuTitle,
									destination: getUrlFromMapping(
										mappings,
										menuItem.elements.internal_link.linkedItems[0]?.system
											.codename ?? homepageCodename
									),
								} as MenuLinkTwoLevel;
							}

							if (menuItem.elements.external_link.value !== "") {
								const externalLink = menuItem.elements.external_link.value as
									| string
									| undefined;
								return {
									title: subMenuTitle,
									destination: externalLink,
								} as MenuLinkTwoLevel;
							}

							// default to homepage
							return {
								title: subMenuTitle,
								destination: "/",
							} as MenuLinkTwoLevel;
						}),
					},
				};
			})
			.reduce((acc, curr) => ({ ...acc, ...curr }), {});
	return menuLinks;
}

export async function getNavigation(
	navigationType: string | undefined,
	mappings: SitemapMapping[],
	homepageCodename: string,
	navigationCodename: string,
	isPreview = false,
	lang: string
) {
	if (!navigationType) {
		return null;
	}

	if (
		navigationType &&
		navigationType === contentTypes._navigation_1_level.codename
	) {
		return getOneLevelNavigation(
			mappings,
			homepageCodename,
			navigationCodename,
			isPreview,
			lang
		);
	}

	return getTwoLevelNavigation(
		mappings,
		homepageCodename,
		navigationCodename,
		isPreview,
		lang
	);
}

export async function getBreadcrumbLinks(
	mappings: SitemapMapping[],
	slug: string[],
	isPreview = false,
	lang: string
): Promise<LinkProps[]> {
	const breadcrumbs: LinkProps[] = [];
	const promises = slug.map(async (_slugPart, index) => {
		const slugPartArray = slug.slice(0, index + 1);
		const mapping = mappings.find(
			(m) => m.slug.join("/") === slugPartArray.join("/")
		);

		const pageCodename = mapping?.codename || "";
		const title = await getPageTitle(pageCodename, isPreview, lang);

		return {
			title,
			destination: `/${slugPartArray.join("/")}`,
			linkType: LinkType.Breadcrumb,
			children: title,
		} as LinkProps;
	});

	breadcrumbs.push(...(await Promise.all(promises)));
	return breadcrumbs;
}
export async function getGlobalMetadata(
	homepageCodename: string,
	isPreview = false
): Promise<Metadata> {
	const response = await deliveryClient
		.item<HomepageModel>(homepageCodename)
		.queryConfig({
			usePreviewMode: isPreview,
		})
		.depthParameter(1)
		.toPromise();

	const siteSettings = response.data.item.elements.site_settings.linkedItems[0];

	const globalMetaData: Metadata = {
		icons: {
			apple: [
				{
					url:
						siteSettings?.elements.site_favicon_snippet__n180x180.value[0]
							?.url ?? "",
					sizes: "180x180",
				},
			],
			icon: [
				{
					url:
						siteSettings?.elements.site_favicon_snippet__desktop_shortcut_icons
							.value[0]?.url ?? "",
					sizes: "96x96",
				},
				{
					url:
						siteSettings?.elements.site_favicon_snippet__taskbar_shortcut_icons
							.value[0]?.url ?? "",
					sizes: "32x32",
				},
				{
					url:
						siteSettings?.elements.site_favicon_snippet__browser_favicons
							.value[0]?.url ?? "",
					sizes: "16x16",
				},
			],
			shortcut:
				siteSettings?.elements.site_favicon_snippet__taskbar_shortcut_icons
					.value[0]?.url ?? "",
		},
	};

	return globalMetaData;
}

export const getTranslationsForContent = async (
	codename: string,
	isDraftMode: boolean
): Promise<Language[] | []> => {
	const localisationService = new LocalisationService({
		isPreview: isDraftMode,
		environmentId: process.env.NEXT_PUBLIC_KONTENT_ENVIRONMENT_ID ?? "",
		previewApiKey: process.env.KONTENT_PREVIEW_API_KEY ?? "",
		assetsDomain: process.env.NEXT_PUBLIC_ASSETS_DOMAIN ?? "",
		languageCodename: DEFAULT_LOCALE,
	});

	const translations =
		await localisationService.getTranslationsForContent(codename);

	return translations;
};

const setMetadataAlternates = async (
	codename: string,
	url: string,
	isDraftMode: boolean
): Promise<{
	canonical: string;
	languages: Record<string, string>;
}> => {
	const languages = await getTranslationsForContent(codename, isDraftMode);

	const alternates = {
		canonical: url,
		languages: {},
	};

	languages.forEach((language) => {
		const languageUrl =
			language.codename === DEFAULT_LOCALE ? url : `${language.codename}${url}`;
		alternates.languages = {
			...alternates.languages,
			[language.codename]: languageUrl,
		};
	});

	return alternates;
};

export async function getPageMetadata(
	params: RouteProps,
	isDraftMode: boolean
): Promise<Metadata> {
	const { lang } = params;
	const props = await getPageStaticPropsForPath(
		params.slug ?? [],
		process.env.HOMEPAGE_CODENAME ?? "homepage",
		isDraftMode,
		lang
	);

	let pageTitle = "";
	let siteName = "";
	let metaTitle = "";
	if (props.siteSettings.elements.site_name.value) {
		siteName = props.siteSettings.elements.site_name.value;
	}
	if (props.page?.item.elements.title.value) {
		pageTitle = props.page.item.elements.title.value;
	}
	if (props.page?.item.elements.seo_snippet__meta_title.value) {
		metaTitle = props.page.item.elements.seo_snippet__meta_title.value;
	} else {
		metaTitle = `${pageTitle} | ${siteName}`;
	}

	const pageMetadata: Metadata = {
		metadataBase: new URL(props.siteSettings.elements.site_url.value),
		title: metaTitle,
		description: props.page?.item.elements.seo_snippet__meta_description.value,
		twitter: {
			site: props.siteSettings.elements.twitter_handle.value,
			title: metaTitle,
			description:
				props.page?.item.elements.seo_snippet__meta_description.value,
			images: {
				url:
					props.page?.item.elements.seo_snippet__meta_image.value[0]?.url ??
					props.siteSettings.elements.default_meta_image.value[0]?.url ??
					"",
				alt:
					props.page?.item.elements.seo_snippet__meta_image.value[0]
						?.description ?? "",
			},
		},
		openGraph: {
			title: metaTitle,
			description:
				props.page?.item.elements.seo_snippet__meta_description.value,
			type: "website",
			url: `${props.siteSettings.elements.site_url.value}${props.url ?? ""}`,
			siteName: props.siteSettings.elements.site_name.value,
			locale: lang,
			images:
				props.page?.item.elements.seo_snippet__meta_image.value.length !== 0
					? props.page?.item.elements.seo_snippet__meta_image.value.map(
							(image) => {
								return {
									url: image.url,
									alt: image.description ?? undefined,
									width: image.width ?? undefined,
									height: image.height ?? undefined,
								};
							}
						)
					: props.siteSettings.elements.default_meta_image.value.map(
							(image) => {
								return {
									url: image.url,
									alt: image.description ?? undefined,
									width: image.width ?? undefined,
									height: image.height ?? undefined,
								};
							}
						),
		},
		alternates: await setMetadataAlternates(
			props.page?.item.system.codename ?? "",
			props.url ?? "",
			isDraftMode
		),
		other: {
			"brc:internalsearch:visible": "true",
		},
	};

	if (isPage(props.page?.item)) {
		const externalSearchVisibility =
			props.page.item.elements.page_visibility__external_search;
		const internalSearchVisibility =
			props.page.item.elements.page_visibility__internal_search;

		if (externalSearchVisibility) {
			const value =
				!externalSearchVisibility.value.length ||
				externalSearchVisibility.value[0]?.codename === "true"
					? undefined
					: "noindex";
			pageMetadata.robots = value;
		}

		if (internalSearchVisibility) {
			const value =
				!internalSearchVisibility.value.length ||
				internalSearchVisibility.value[0]?.codename === "true"
					? "true"
					: "false";

			pageMetadata.other = {
				"brc:internalsearch:visible": value,
			};
		}
	}

	return pageMetadata;
}

export const getPathMapping = (
	mappings: SitemapMapping[],
	slug: string[]
): SitemapMapping | undefined => {
	return mappings.find((path) => path.slug.join("#") === slug.join("#"));
};

export const validatePath = async (
	endOfSlug: string | undefined,
	isPreview: boolean,
	lang: string
): Promise<boolean> => {
	const isValidLanguage = availableLanguagesForCollection.includes(lang);

	// if the language is not valid then return false
	if (!isValidLanguage) {
		return false;
	}

	// if no slug was passed in then it's the homepage
	if (!endOfSlug) {
		return true;
	}

	try {
		const results = await deliveryClient
			.items()
			.inFilter("system.type", AppConfig.page_types)
			.equalsFilter("elements.slug", endOfSlug)
			.queryConfig({
				usePreviewMode: isPreview,
			})
			.languageParameter(lang)
			.toPromise();

		return results.data.items.length > 0;
	} catch (error) {
		console.log(`validatePath: ${endOfSlug}`, error);
		return false;
	}
};

export const getDictionaryContent = async (
	lang: string,
	preview: boolean
): Promise<DictionaryModel | null> => {
	try {
		const homepageWithDictionary = await deliveryClient
			.item<HomepageModel>(process.env.HOMEPAGE_CODENAME ?? "homepage")
			.excludeElementsParameter([
				"subpages",
				"site_settings",
				"content",
				"hero",
			])
			.languageParameter(lang)
			.queryConfig({
				usePreviewMode: preview,
			})
			.toPromise();

		return (
			homepageWithDictionary.data.item.elements.dictionaries.linkedItems[0] ??
			null
		);
	} catch (error) {
		console.log(`getDictionaryContent: ${lang}`, error);
		return null;
	}
};

/**
 * Return props for a page in Kontent.ai given the slug
 *
 * @param slug - string array containing components of a pages slug
 * @param preview - determines whether to use preview mode
 * @returns props collection used to build page
 *
 */
export const getPageStaticPropsForPath = async (
	slug: string[],
	homepageCodename: string,
	preview: boolean,
	lang: string
) => {
	const mappings = await getSitemapMappings(homepageCodename, preview, lang); // TODO could be cached

	const settingsService = new SettingsService({
		isPreview: preview,
		environmentId: process.env.NEXT_PUBLIC_KONTENT_ENVIRONMENT_ID || "",
		previewApiKey: process.env.KONTENT_PREVIEW_API_KEY || "",
		assetsDomain: process.env.NEXT_PUBLIC_ASSETS_DOMAIN || "",
		languageCodename: lang,
	});

	// then get the path mapping
	const pathMapping = getPathMapping(mappings, slug);

	// check if path mapping exists, if it doesn't then the page doesn't exist so return 404
	if (!pathMapping) {
		notFound();
	}

	const siteSettings = await settingsService.getSiteSettings(homepageCodename);

	const breadcrumbLinks = await getBreadcrumbLinks(
		mappings,
		slug,
		preview,
		lang
	);

	const navigationCodename =
		siteSettings.elements.header.linkedItems[0]?.elements.navigation.value[0] ||
		"navigation";

	const navigationType =
		siteSettings.elements.header.linkedItems[0]?.elements.navigation
			.linkedItems[0]?.system.type;

	const menuItems = await getNavigation(
		navigationType,
		mappings,
		homepageCodename,
		navigationCodename,
		preview,
		lang
	);

	const vadSearch = await getVadSearch();
	const vadRecord = await getVadRecord();

	if (pathMapping === undefined) {
		return {
			mappings,
			siteSettings,
			menuItems,
			vadSearch,
			vadRecord,
		};
	}
	const url = getUrlFromMapping(mappings, pathMapping.codename);

	// Loading content data
	const pageResponse = await deliveryClient
		.item<PageModel | HomepageModel>(pathMapping.codename)
		.depthParameter(12)
		.queryConfig({
			usePreviewMode: preview,
		})
		.languageParameter(lang)
		.excludeElementsParameter(["site_settings", "subpages"])
		.toPromise()
		.then((response) => response.data);

	return {
		mappings,
		breadcrumbLinks,
		siteSettings,
		url,
		menuItems,
		vadSearch,
		vadRecord,
		page: pageResponse,
		isSingleLevelNavigation:
			navigationType === contentTypes._navigation_1_level.codename,
	};
};
