import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { type RecordsTableProps } from "./records-table.types";

export function RecordsTable({ rows }: RecordsTableProps) {
	const skeletonRows = () => {
		const rows: JSX.Element[] = [];
		for (let rowCount = 0; rowCount < 24; rowCount++) {
			rows.push(
				<React.Fragment key={rowCount}>
					<dt className="min-h-12 text-white bold border-b border-grey-20 bg-blue-tint py-2.5 px-4 w-full md:w-3/12 md:text-base lg:border-white lg:border-b">
						<Skeleton count={1} />
					</dt>
					<dd className="min-h-12 border-b border-blue-tint bg-white py-2.5 px-4 w-full md:w-9/12 md:text-base lg:border-blue-tint lg:border-b">
						<Skeleton count={1} />
					</dd>
				</React.Fragment>
			);
		}

		return rows;
	};

	return (
		<div className="p-4 md:p-6">
			<dl className="flex flex-wrap border-blue-tint max-content mx-auto border-l border-r overflow-hidden first:border-t">
				{!Object.keys(rows).length && skeletonRows()}
				{Object.keys(rows).map((key, idx) => {
					return (
						<React.Fragment key={idx}>
							<dt className="min-h-12 text-white bold border-b border-grey-20 bg-blue-tint py-2.5 px-4 w-full md:w-3/12 md:text-base lg:border-white lg:border-b">
								{key}
							</dt>
							<dd className="min-h-12 border-b border-blue-tint bg-white py-2.5 px-4 w-full md:w-9/12 md:text-base lg:border-blue-tint lg:border-b">
								{rows[key]}
							</dd>
						</React.Fragment>
					);
				})}
			</dl>
		</div>
	);
}
