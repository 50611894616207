"use client";

import React from "react";
import { CardContainer as CardContainerBrc } from "@britishredcross/component-library/src/components/organisms/card-container/card-container";
import { Heading as HeadingBrc } from "@britishredcross/component-library/src/components/atoms/heading/heading";
import { HeadingLevel } from "@britishredcross/component-library/src/components/atoms/heading/heading.types";
import { PlainText as PlainTextBrc } from "@britishredcross/component-library/src/components/atoms/plain-text/plain-text";
import { type CardContainerModel } from "@britishredcross/kontent-integration/src/models";
import { type SitemapMapping } from "@britishredcross/kontent-integration/src/services/settings-service";
import { BasicCard } from "./cards/basic-card";
import { StandaloneCard } from "./cards/standalone-card";

export function CardContainer({
	cardContainerItem,
	mappings,
}: {
	cardContainerItem: CardContainerModel;
	mappings: SitemapMapping[];
}): JSX.Element {
	const numberOfCards = cardContainerItem.elements.cards.linkedItems.length;
	const isStandalone = numberOfCards === 1;

	const cards = cardContainerItem.elements.cards.linkedItems.map((cardItem) => {
		if (isStandalone) {
			return (
				<StandaloneCard
					cardItem={cardItem}
					mappings={mappings}
					key={cardItem.system.id}
				/>
			);
		}
		return (
			<BasicCard
				cardItem={cardItem}
				mappings={mappings}
				key={cardItem.system.id}
			/>
		);
	});

	return (
		<CardContainerBrc
			subtitle={
				<PlainTextBrc>{cardContainerItem.elements.text.value}</PlainTextBrc>
			}
			title={
				<HeadingBrc level={HeadingLevel.H2}>
					{cardContainerItem.elements.title.value}
				</HeadingBrc>
			}
		>
			{cards.map((card) => card)}
		</CardContainerBrc>
	);
}
