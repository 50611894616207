import React from "react";
import { FileList as FileListBrc } from "@britishredcross/component-library/src/components/molecules/file-list/file-list";
import { type FileListModel } from "@britishredcross/kontent-integration/src/models";

export function FileList(props: FileListModel): JSX.Element {
	const fileListDetails = props.elements.files.linkedItems.map(
		(fileDetail) => ({
			fileLinks: [
				{
					title: fileDetail.system.name,
					destination: fileDetail.elements.file.value[0]?.url ?? "",
				},
			],
			fileFormat:
				fileDetail.elements.file.value[0]?.type?.split("/")[1]?.toUpperCase() ??
				"",
			fileSize: fileDetail.elements.file.value[0]
				? bytesToSize(fileDetail.elements.file.value[0].size)
				: "0",
		})
	);

	return (
		<FileListBrc
			downloadLinks={fileListDetails}
			fileListTitle={props.elements.title.value}
		/>
	);
}

export function bytesToSize(bytes: number): string {
	const sizes: string[] = ["Bytes", "KB", "MB", "GB", "TB"];
	if (bytes === 0) return "n/a";
	const i: number = parseInt(
		Math.floor(Math.log(bytes) / Math.log(1024)).toString()
	);
	if (i === 0) return `${bytes.toString()} ${sizes[i] ?? ""}`;
	return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i] ?? ""}`;
}
