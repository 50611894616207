import { createDeliveryClient } from "@kontent-ai/delivery-sdk";
import packageInfo from "@/package.json";

const sourceTrackingHeaderName = "X-KC-SOURCE";

export const deliveryClient = createDeliveryClient({
	environmentId: process.env.NEXT_PUBLIC_KONTENT_ENVIRONMENT_ID || "",
	previewApiKey: process.env.KONTENT_PREVIEW_API_KEY,
	assetsDomain: process.env.NEXT_PUBLIC_ASSETS_DOMAIN || "",
	defaultRenditionPreset: "default",
	globalHeaders: (_queryConfig) => [
		{
			header: sourceTrackingHeaderName,
			value: `${packageInfo.name};${packageInfo.version}`,
		},
	],
});
