import React from "react";
import { Jotform as JotformBrc } from "@britishredcross/component-library/src/components/atoms/jotform/jotform";
import { type JotformModel } from "@britishredcross/kontent-integration/src/models";
import { WebSpotlightConstants } from "../../utils/web-spotlight";

export function Jotform({
	jotformItem,
	preview,
}: {
	jotformItem: JotformModel;
	preview: boolean;
}): JSX.Element {
	let dataKontentElementCodename;
	let dataKontentItemId;
	if (preview) {
		dataKontentElementCodename = {
			[WebSpotlightConstants.dataKontentElementCodename]:
				jotformItem.system.codename,
		};
		dataKontentItemId = {
			[WebSpotlightConstants.dataKontentItemId]: jotformItem.system.id,
		};
	}

	return (
		<JotformBrc
			formId={jotformItem.elements.form_id.value}
			{...dataKontentElementCodename}
			{...dataKontentItemId}
		/>
	);
}
