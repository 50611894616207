import React from "react";
import { LinkType } from "@britishredcross/component-library/src/components/atoms/link/link.types";
import { Link as LinkBrc } from "@britishredcross/component-library/src/components/atoms/link/link";
import { type LinkModel } from "@britishredcross/kontent-integration/src/models";
import { type SitemapMapping } from "@britishredcross/kontent-integration/src/services/settings-service";
import { type IContentItem } from "@kontent-ai/delivery-sdk";
import { getUrlFromMapping } from "@/utils/path";

export function Link({
	linkItem,
	mappings,
}: {
	linkItem: LinkModel;
	mappings: SitemapMapping[];
}): JSX.Element {
	let url;
	let internalLinkItem: IContentItem | undefined;

	// check if the link is internal or external

	if (
		linkItem.elements.internal_link &&
		linkItem.elements.internal_link.linkedItems.length > 0
	) {
		internalLinkItem = linkItem.elements.internal_link.linkedItems[0];
	}

	// if the link is not internal, use the external link
	if (!internalLinkItem) {
		url = linkItem.elements.external_link?.value ?? "";
	} else {
		url = getUrlFromMapping(mappings, internalLinkItem.system.codename);
	}
	return (
		<LinkBrc destination={url} linkType={LinkType.Default}>
			{linkItem.elements.display.value}
		</LinkBrc>
	);
}
