import React from "react";
import { Heading } from "../../atoms/heading/heading";
import { HeadingLevel } from "../../atoms/heading/heading.types";
import { Link } from "../../atoms/link/link";
import { LinkType } from "../../atoms/link/link.types";
import { type FileListProps } from "./file-list.types";

export function FileList({
	fileListTitle,
	downloadLinks,
	linkType = LinkType.Default,
}: FileListProps): JSX.Element {
	return (
		<div className="max-content mx-auto">
			<div className="px-4 max-w-full mb-6 md:mb-12 lg:mb-16 lg:px-0">
				{fileListTitle ? (
					<div className="prose prose-h2:mt-0 prose-h2:mb-6">
						<Heading level={HeadingLevel.H2}>{fileListTitle}</Heading>
					</div>
				) : null}

				<ul className="list-none">
					{Object.entries(downloadLinks).map(
						([key, { fileLinks, fileFormat, fileSize }]) => (
							<React.Fragment key={key}>
								{Array.isArray(fileLinks) &&
									fileLinks.map((link, linkIdx) => (
										<li key={`${link.destination}_${linkIdx.toString()}`}>
											<Link
												linkType={linkType}
												destination={link.destination}
												download
											>
												{link.title}
											</Link>
											<p className="text-xs">
												{fileFormat ? (
													<span className="border-r pr-2 mr-2">
														{fileFormat}
													</span>
												) : null}
												{fileSize ? <span>{fileSize}</span> : null}
											</p>
										</li>
									))}
							</React.Fragment>
						)
					)}
				</ul>
			</div>
		</div>
	);
}
