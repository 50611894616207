"use client";
import React, { Suspense, useEffect, useState } from "react";
import type { PaginationProps } from "@britishredcross/component-library/src/components/molecules/pagination/pagination.types";
import { PageSkeleton } from "@britishredcross/component-library/src/components/atoms/page-skeleton/page-skeleton";
import { Pagination } from "@britishredcross/component-library/src/components/molecules/pagination/pagination";
import { SearchForm } from "@britishredcross/component-library/src/components/molecules/search-form/search-form";
import { VadSearchResults } from "@britishredcross/component-library/src/components/organisms/vad-search-results/vad-search-results";
import { useRouter, useSearchParams, usePathname } from "next/navigation";
import type { VadSearchModel } from "@britishredcross/kontent-integration/src/models";
import { useStore } from "../../hooks/use-store";

function VadSearchComponent(props: VadSearchModel): JSX.Element {
	const router = useRouter();
	const searchParams = useSearchParams();
	const pathName = usePathname();

	const [searchTerm, setSearchTerm] = useState(searchParams.get("searchTerm"));

	const { vadApi } = useStore();

	const [page, setPage] = useState(1);

	const perPage = 5;

	// Filter passed in via query string
	const [filter, setFilter] = useState(
		searchParams.get("filter") ??
			props.elements.filters.linkedItems[0]?.elements.default.linkedItems[0]
				?.elements.value.value
	);

	useEffect(() => {
		if (searchTerm) {
			vadApi.search(searchTerm, filter !== "" ? filter : undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- only want to run this effect when the search term, path name or filter changes
	}, [searchTerm, pathName, filter]);

	const getPaginationProps = (): PaginationProps => {
		const totalResults = vadApi.searchResults.length;
		const hasRemainder = totalResults % perPage;

		let totalPages = parseInt(
			(totalResults / perPage).toString().split(".")[0] ?? "",
			10
		);

		if (hasRemainder) {
			totalPages += 1;
		}
		const paginationProps = {
			currentPage: page,
			totalPages,
			onClick: (pageNumber) => {
				setPage(pageNumber);
			},
		} as PaginationProps;

		return paginationProps;
	};

	const onSearch = (
		searchQuery: string,
		searchFilter: { name: string; value: string }
	): void => {
		setFilter(searchFilter.value);
		setSearchTerm(searchQuery);
		const filterQuery = `&filter=${searchFilter.value}`;
		router.replace(`/search?searchTerm=${searchQuery}${filterQuery}`, {
			scroll: false,
		});
	};

	return (
		<>
			<SearchForm
				{...{
					title: "Search our records",
					onSearch: (searchQuery, searchFilter) => {
						onSearch(searchQuery as string, {
							name: searchFilter?.[0]?.name ?? "",
							value: searchFilter?.[0]?.value ?? "",
						});
					},
					searchValue: searchTerm ?? undefined,
					buttonText: props.elements.button_text.value,
					inputLabel: props.elements.input_label.value,
					filterList: props.elements.filters.linkedItems[0]?.elements.filters
						? [
								{
									name: "Filter",
									label: "Filter",

									options:
										props.elements.filters.linkedItems[0]?.elements.filters.linkedItems.map(
											(filterItem) => ({
												title: filterItem.elements.key.value,
												value: filterItem.elements.value.value,
											})
										),
									selectedValue: filter,
								},
							]
						: undefined,
				}}
			/>
			{vadApi.resultsLoading ? (
				<div className="mx-auto my-10 max-content">
					<PageSkeleton />
				</div>
			) : (
				<>
					{vadApi.searchResults.length ? (
						<VadSearchResults
							totalRecords={vadApi.searchResults.length}
							vadRecords={vadApi.searchResults
								.slice((page - 1) * perPage, page * perPage)
								.map(
									(result: {
										RowKey: string;
										christian_names: string;
										surname: string;
										county: string;
										date_of_engagement: string;
										date_of_termination: string;
									}) => ({
										link: `/record?rowKey=${result.RowKey}`,
										heading: `${result.christian_names} ${result.surname}`,
										county: result.county,
										dateOfEngagement: result.date_of_engagement,
										endOfEngagement: result.date_of_termination,
									})
								)}
						/>
					) : null}
					{vadApi.searchResults.length ? (
						<Pagination {...getPaginationProps()} />
					) : null}
				</>
			)}
		</>
	);
}

export function VadSearch(props: VadSearchModel): JSX.Element {
	return (
		<Suspense>
			<VadSearchComponent {...props} />
		</Suspense>
	);
}
