//make this typesafe
interface AppConfigType {
	site_name: string;
	title: string;
	description: string;
	locale: string;
	page_types: string[];
}
export const AppConfig: AppConfigType = {
	site_name: "British Red Cross Site",
	title: "British Red Cross - Web Platform Site",
	description: "Starter site for the British Red Cross Web Platform",
	locale: "en-gb",
	page_types: ["page", "homepage", "press_release", "shop", "redirect"],
};
