import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { type PageSkeletonProps } from "./page-skeleton.types";

export function PageSkeleton({ lineCount }: PageSkeletonProps) {
	return (
		<div data-testid="loading-skeleton">
			<Skeleton count={2} />
			<Skeleton count={1} circle width={30} />
			<Skeleton count={lineCount || 5} />
		</div>
	);
}
