import(/* webpackMode: "eager", webpackExports: ["DictionaryProvider"] */ "/opt/build/repo/apps/nextjs/app/context/dictionary.context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CardContainer"] */ "/opt/build/repo/apps/nextjs/components/content/card-container.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/apps/nextjs/components/content/image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSelector"] */ "/opt/build/repo/apps/nextjs/components/content/language-selector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchBox"] */ "/opt/build/repo/apps/nextjs/components/content/search-box.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VadRecord"] */ "/opt/build/repo/apps/nextjs/components/content/vad-record.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VadSearch"] */ "/opt/build/repo/apps/nextjs/components/content/vad-search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomepageHero"] */ "/opt/build/repo/apps/nextjs/components/hero/homepage-hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KontentAISmartLinkInit"] */ "/opt/build/repo/apps/nextjs/components/kontentai-smartlink-browser-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchClient"] */ "/opt/build/repo/apps/nextjs/components/search/search-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/opt/build/repo/apps/nextjs/hooks/use-store/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/opt/build/repo/packages/component-library/src/components/atoms/image/image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Jotform"] */ "/opt/build/repo/packages/component-library/src/components/atoms/jotform/jotform.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/component-library/src/components/atoms/link/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/opt/build/repo/packages/component-library/src/components/molecules/accordion/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Breadcrumb"] */ "/opt/build/repo/packages/component-library/src/components/molecules/breadcrumb/breadcrumb.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MapStatic"] */ "/opt/build/repo/packages/component-library/src/components/molecules/map-static/map-static.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShopContactDetails"] */ "/opt/build/repo/packages/component-library/src/components/molecules/shop-contact-details/shop-contact-details.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShopOpeningHours"] */ "/opt/build/repo/packages/component-library/src/components/molecules/shop-opening-hours/shop-opening-hours.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/opt/build/repo/packages/component-library/src/components/molecules/video/video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["XProfile"] */ "/opt/build/repo/packages/component-library/src/components/molecules/x-profile/x-profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactBanner"] */ "/opt/build/repo/packages/component-library/src/components/organisms/contact-banner/contact-banner.tsx");
