import React from "react";
import { Quote as QuoteBrc } from "@britishredcross/component-library/src/components/atoms/quote/quote";
import type { QuoteModel } from "@britishredcross/kontent-integration/dist/esm/models";
import {
	nodeParse,
	transformToPortableText,
} from "@kontent-ai/rich-text-resolver";
import { toHTML } from "@portabletext/to-html";

export function Quote(props: QuoteModel): JSX.Element {
	const { attribution, quote } = props.elements;

	const parsedTree = nodeParse(quote.value);
	const portableText = transformToPortableText(parsedTree);
	const resolvedHtml = toHTML(portableText);

	return <QuoteBrc attribution={attribution.value}>{resolvedHtml}</QuoteBrc>;
}
