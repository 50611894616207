import React from "react";
import { CTABanner as CTABannerBrc } from "@britishredcross/component-library/src/components/molecules/cta-banner/cta-banner";
import { Link } from "@britishredcross/component-library/src/components/atoms/link/link";
import { LinkType } from "@britishredcross/component-library/src/components/atoms/link/link.types";
import type { CtaBannerModel } from "@britishredcross/kontent-integration/dist/esm/models";

export function CtaBanner(props: CtaBannerModel): JSX.Element {
	const { description, link, title } = props.elements;
	const linkInfo = link.linkedItems[0]?.elements;

	const linkType =
		link.linkedItems[0]?.system?.type === "donate_cta"
			? "ButtonLinkLightBg"
			: "Default";

	return (
		<CTABannerBrc
			description={description.value}
			link={
				<Link
					destination={linkInfo?.external_link.value ?? ""}
					linkType={LinkType[linkType]}
				>
					{linkInfo?.display.value}
				</Link>
			}
			title={title.value}
		/>
	);
}
