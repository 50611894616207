import React from "react";
import { Sitemap as SitemapBrc } from "@britishredcross/component-library/src/components/molecules/sitemap/sitemap";

export function Sitemap(props: any) {
	const links = props.mappings
		.filter((mapping) => mapping.pageVisibility?.sitemap_page)
		.filter(
			(mapping: { title: string; slug: string[] }) => mapping.slug.length > 0
		)
		.map((mapping: { title: string; slug: string[] }) => ({
			title: mapping.title,
			slug: mapping.slug,
		}));
	return <SitemapBrc links={links} />;
}
