"use client";
import React from "react";
import { SearchForm } from "@britishredcross/component-library/src/components/molecules/search-form/search-form";
import { useRouter } from "next/navigation";
import type { SearchBoxModel } from "@britishredcross/kontent-integration/dist/esm/models";

export function SearchBox(props: SearchBoxModel) {
	const router = useRouter();

	const title = props.elements.title.value;
	const searchUrl = props.elements.search_url.value;
	const buttonText = props.elements.button_text.value;
	const inputLabel = props.elements.input_label.value;

	return (
		<SearchForm
			buttonText={buttonText}
			inputLabel={inputLabel}
			onSearch={(searchTerm) => {
				router.push(`${searchUrl}${searchTerm}`);
			}}
			title={title}
		/>
	);
}
